<template>
  <b-sidebar
    id="edit-vehicle-sidebar"
    :visible="isEditVehicleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-vehicle-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Vehicle Info
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(handleUpdateVehicleInfo)"
        >
          <b-form-group
            label="Registration"
            label-for="registration"
          >
            <validation-provider
              #default="{ errors }"
              name="Registration"
              rules="required"
            >
              <b-form-input
                id="registration"
                v-model="newVehicleData.registration"
                placeholder="Enter Vehicle Registration"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Make"
            label-for="make"
          >
            <validation-provider
              #default="{ errors }"
              name="Make"
              rules="required"
            >
              <b-form-input
                id="make"
                v-model="newVehicleData.make"
                placeholder="Enter Vehicle Make"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Model"
            label-for="model"
          >
            <validation-provider
              #default="{ errors }"
              name="Model"
              rules="required"
            >
              <b-form-input
                id="model"
                v-model="newVehicleData.model"
                placeholder="Enter Vehicle Model"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Mileage"
            label-for="mileage"
          >
            <validation-provider
              #default="{ errors }"
              name="Mileage"
              rules="required|integer"
            >
              <b-form-input
                id="mileage"
                v-model="newVehicleData.mileage"
                placeholder="Enter Vehicle Mileage"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-form-checkbox
              v-model="mileageCorrectionOverride"
              :disabled="newVehicleData.mileage >= initialMileage"
              class="mt-50 custom-control-warning"
              :value="true"
              :unchecked-value="false"
            >
              Override mileage
            </b-form-checkbox>
          </b-form-group>

          <div v-if="!!newVehicleData">
            <b-card
              no-body
              class="border-primary"
            >
              <b-card-header class="d-flex justify-content-between pt-75 pb-25 px-1">
                <h3 class="mb-0">
                  {{ newVehicleData.registration }}
                </h3>
              </b-card-header>
              <b-card-body class="px-1">
                <ul
                  class="list-unstyled my-1"
                >
                  <li>
                    <span class="align-middle">Make: {{ newVehicleData.make ? newVehicleData.make : 'N/A' }}</span>
                  </li>
                  <li>
                    <span class="align-middle">Model: {{ newVehicleData.model ? newVehicleData.model : 'N/A' }}</span>
                  </li>
                  <li>
                    <span class="align-middle">Mileage: {{ newVehicleData.mileage ? newVehicleData.mileage + ' mi' : 'N/A' }}</span>
                  </li>
                </ul>
              </b-card-body>
            </b-card>
          </div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                class="mr-50"
              />
              {{ isLoading ? "Updating..." : "Update" }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="flat-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>

  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormInput,
  BBadge,
  BFormGroup,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BSpinner,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    BSidebar,
    BForm,
    BFormInput,
    flatPickr,
    BCard,
    BCardBody,
    BCardHeader,
    BBadge,
    BSpinner,
    BFormGroup,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    // Form Validation
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditVehicleSidebarActive',
    event: 'update:is-edit-vehicle-sidebar-active',
  },
  props: {
    isEditVehicleSidebarActive: {
      type: Boolean,
      required: true,
    },
    vehicleData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      required,
      integer,
      newVehicleData: this.$props.vehicleData ? this.$props.vehicleData : {},
      isLoading: false,
      initialMileage: this.vehicleData ? this.vehicleData.mileage : 0,
      mileageCorrectionOverride: false,
    }
  },
  methods: {
    handleUpdateVehicleInfo() {
      const vehicleData = {
        registration: this.newVehicleData.registration,
        make: this.newVehicleData.make,
        model: this.newVehicleData.model,
        mileage: Number(this.newVehicleData.mileage),
        overrideMileage: this.mileageCorrectionOverride,
      }
      const vehicleId = this.vehicleData ? this.vehicleData.id : null

      if (vehicleId) return this.updateVehicle(vehicleId, vehicleData)
      return this.addVehicle(vehicleData)
    },
    updateVehicle(vehicleId, vehicleData) {
      this.isLoading = true
      store.dispatch('app-vehicle/updateVehicle', { vehicleId, vehicleData })
        .then(response => {
          this.newVehicleData = response.data
          this.initialMileage = response.data.mileage
          this.mileageCorrectionOverride = false
          this.$emit('updateVehicleInfo', this.newVehicleData)
          this.$emit('update:is-edit-vehicle-sidebar-active', false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Vehicle updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully updated this vehicle.',
            },
          })
          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          if (vehicleId) {
            store.dispatch('app-vehicle/fetchVehicle', { id: vehicleId })
              .then(response => {
                this.$emit('updateVehicleInfo', response.data)
                this.newVehicleData = response.data
              })
          }

          this.$emit('update:is-edit-vehicle-sidebar-active', false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Vehicle update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while updating this vehicle.',
            },
          })
          this.isLoading = false
        })
    },
    addVehicle(vehicleData) {
      this.isLoading = true
      store.dispatch('app-vehicle/addVehicle', { vehicleData })
        .then(response => {
          this.$router.push({ name: 'vehicles-view', params: { id: response.data.id } })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Vehicle added',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully added a new vehicle.',
            },
          })

          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error adding new vehicle',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while adding this vehicle.',
            },
          })
          this.isLoading = false
        })
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-vehicle-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
